.mission-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
  padding: 20px;
  max-width: 1200px;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5deb3;
  border: 2px solid #8b4513;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
}

.play-mission {
  background-color: #ffe4b5;
  font-family: "Pirate One", cursive;
  padding: 20px;
  border: 2px solid #8b4513;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  max-width: 600px;
  margin: auto;
}

.mission-heading {
  text-align: center;
  color: #4b0082;
  font-size: 2em;
  margin-bottom: 20px;
}

.role-heading {
  color: #8b0000;
  font-size: 1.5em;
  margin: 10px 0;
  border-bottom: 2px solid #8b4513;
  padding-bottom: 5px;
}

/* Ship Stats */

.stat-container {
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.stat-item {
  background-color: #ffe8c1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.progress-bar-container {
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  height: 30px;
  width: 100%;
  margin-top: 5px;
}

.progress-bar {
  height: 100%;
  text-align: center;
  line-height: 30px;
  color: white;
  transition: width 0.3s ease;
}

ul {
  list-style-type: none;
  padding: 0;
}

.mission-list {
  color: #4b0082;
  padding: 5px 0;
  position: relative;
  font-size: 1.1em;
  margin-left: 20px;
}

.mission-list::before {
  content: "☠️";
  font-size: 1em;
  margin-right: 10px;
  color: #8b0000;
  position: absolute;
  left: -25px;
}

.mission-list:hover {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.mission-image-container {
  display: flex;
  flex-direction: column;
}

.mission-image {
  max-width: 800px;
  padding: 20px;
  border: 2px solid #8b0000;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.play-mission-button {
  max-width: 200px;
  background-color: #700000;
}

.play-mission-button:hover {
  background-color: #8b0000;
}

/* Outcome Message */

.outcomeMessage-container {
  background-color: #ffe8c1;
  position: relative;
  font-family: "Pirate One", cursive;
  width: 60vw;
  margin: 10% auto;
  padding: 4%;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 2px solid #d4a373;
  color: #333;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.outcomeMessage-container:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.outcome-message {
  padding: 3%;
}

.outcome-close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px; /* Optional: Add margin to adjust spacing from the corner */
}

/* Outcome Message Loading */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-circle {
  width: 60px;
  height: 60px;
  border: 8px solid #d4a373;
  border-top: 8px solid #2c2c2c;
  border-radius: 50%;
  animation: rotate 1.2s linear infinite;
  margin: 0 auto;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.loading-circle :hover {
  animation: rotate 1.2s ease-in-out infinite;
  transform: scale(1.1);
}

/* Container styling */
.leaderboard-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  background-color: #2e2e2e;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

/* Title styling */
.leaderboard-title {
  color: #f0db4f;
  font-size: 2rem;
  text-align: center;
  font-family: "Pirata One", cursive;
  margin-bottom: 20px;
}

/* Table styling */
.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

/* Header row styling */
.leaderboard-table thead th {
  background-color: #444;
  color: #f0db4f;
  padding: 12px;
  font-size: 1.1rem;
}

/* Data row styling */
.leaderboard-table tbody tr {
  border-bottom: 1px solid #555;
  background-color: #333;
  color: #f9f9f9;
}

/* Hover effect on rows */
.leaderboard-table tbody tr:hover {
  background-color: #444;
  cursor: pointer;
}

/* Cell padding */
.leaderboard-table td,
.leaderboard-table th {
  padding: 12px;
  text-align: center;
}

/* Weekly points column styling */
.leaderboard-table tbody td:last-child {
  font-weight: bold;
  color: #f0db4f;
}
