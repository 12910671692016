.container {
  background-image: url("../../images/homepageimage.jpg");
  background-repeat: round;
  margin: 0;
  padding: 0;
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr; /* Two columns, each taking up equal space */
}

.login-text {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: italic;
  font-weight:400;
  font-size: 1.5rem;
}

.form-input {
  display: grid;
  place-items: center;
  grid-template-rows: 1.2fr 1fr 1fr;
  width: 75%; /* Occupy the entire width of the grid cell */
  padding: 1rem;
  margin: 20% auto 12%;
  font-size: 1rem;
  outline: none;
  background-color: rgba(0, 0, 0, 0.80);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
}

input[type="text"] {
  padding: 10px; /* Add padding to the input field */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 5px; /* Round the corners */
  font-size: 16px; /* Set the font size */
  outline: none; /* Remove the default focus outline */
  transition: border-color 0.3s ease; /* Add a smooth transition for border color */
  margin: 1.75rem 0 0;
}

/* Style when the input is focused */
input[type="text"]:focus {
  border-color: #007bff; /* Change border color on focus */
}

.form-button {
  width: 15%; /* Occupy the entire width of the grid cell */
  padding: 0.7rem 0;
  color: white;
  background-color: darkcyan;
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.form-button:hover {
  background-color: #008b8b; /* Darker shade of cyan */
}
