.heading-details {
  display: flex;
  width: 80vw;
  padding-left: 3%;
  justify-content: center;
  margin: 20px 0;
  gap: 150px;
}

.shop-option {
  color: black;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.2rem;
}

.shop-option:hover {
  background-color: #333;
  color: #2ecc71;
}

.shop-option.active {
  background-color: #2ecc71;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.drop-rate-container {
  border: 2px solid white;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3% 6%;
  align-items: center;
  justify-content: center;
  margin: 2%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.drop-rate-items {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Spacing between items */
  justify-content: flex-start;
  font-size: 0.9rem;
}

.drop-rate-items p {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  min-width: 150px; /* Adjust as needed */
  text-align: center;
}

.legendary-loot {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.legendary-loot li {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 8px;
  min-width: 130px;
  text-align: center;
}

.chest-image {
  display: block; /* Ensures it takes up only the necessary space */
  margin: 0 3% 20px 0; /* Centers the image and adds space below */
  max-width: 28vw; /* Adjusts the size; change as desired */
  border-radius: 10px; /* Slight rounding for a smoother look */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25); /* Improved subtle shadow */
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease; /* Adds smooth transition */
}

.chest-image:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.4); /* Stronger shadow on hover for depth */
}

.buy-packs-container {
  border: 2px solid white;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2% 4%;
  align-items: center;
  justify-content: center;
  margin: 3% 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.shop-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-text {
  width: 8rem;
  padding: 8px 12px; /* Adjust padding for better spacing */
  margin: 8px 0; /* Add some margin for separation */
  border: 2px solid #4caf50; /* Add a solid border */
  border-radius: 5px; /* Add border radius for rounded corners */
  background-color: #f8f8f8; /* Add a light background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  transition: border-color 0.3s ease; /* Add transition for smooth effects */

  /* Optional: Hover effect */
  &:hover {
    border-color: #2ecc71; /* Change border color on hover */
  }
}

.drop-rate-heading {
  color: #2ecc71;
}

.confirm-transaction-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1;
}

.confirm-transaction {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  border: 3px solid #4caf50; /* Change to a catchy color */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  width: 300px; /* Adjust width as needed */
  text-align: center; /* Center text inside the modal */
}

.transaction-button {
  margin-left: 80%;
}
