.capitalize {
  text-transform: capitalize;
}

.category-heading {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.category-container {
  padding: 15px;
  margin-bottom: 20px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.image-item {
  width: calc(25%);
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.5s ease;
}

.image-item :hover {
  transform: scale(1.1);
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: auto;
}

.homepage-nav {
  background-color: #1a1a1a; /* Softer black for elegance */
  margin: 0;
  padding: 1rem 2rem; /* Adds some horizontal padding */
  display: flex;
  justify-content: flex-end; /* Aligns links to the right */
  gap: 1.5rem; /* Adds spacing between links */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-bottom: 1px solid #333; /* Underline for structure */
  font-family: "Pirata One", cursive;
}

.nav-link {
  color: #d4af37; /* Muted gold for a classy feel */
  text-decoration: none; /* Remove underlines */
  font-weight: 500; /* Slightly bold */
  font-size: 1.1rem; /* Slightly larger text for clarity */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
}

.nav-link:hover {
  color: #ffcc00; /* Gold accent on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}

.nav-link:active {
  color: #e6b800; /* Slightly darker gold when clicked */
}
