.gamecommandcontainer {
  display: flex;
}

.gamecommandlist {
  background-color: lightgrey;
  width: 15%;
  font-size: 1.2rem;
}

.gamecommand {
  border: 1px solid black;
  padding: 3% 7% 3%;
  cursor: pointer;
}
