.register-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #f4f4f4;
  font-family: "Pirata One", cursive;
}

.register-form,
.role-selection,
.confirmation,
.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form label,
.confirmation p {
  margin-bottom: 1rem;
}

input {
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
}

button {
  background: #d4af37;
  color: #1a1a1a;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 0.5rem;
  transition: transform 0.3s, background 0.3s;
}

button:hover {
  transform: scale(1.05);
  background: #ffd700;
}

button:active {
  background: #b8860b;
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.success-message {
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
