.image-container {
  display: flex; /* Use flexbox */
  flex-wrap: wrap; /* Allow wrapping to create new lines */
}

ul {
  list-style-type: none;
}

.card-image {
  width: 300px; /* Set width for each card */
  margin: 10px 20px; /* Add margin between cards */
}

.card-thumbnail {
  width: 400px;
  margin: 10px 20px;
}

.pack-open-content {
  align-items: center;
  margin: 3%;
}

.button-close {
  margin-left: 90%;
}

.cards-container {
  border: 2px solid #ddd; /* Lighter border color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for a 3D effect */
  padding: 20px; /* Inner spacing */
  margin: 20px; /* Outer spacing */
  background-color: #fff; /* White background */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
}

.cards-container:hover {
  transform: translateY(-10px); /* Slight upward movement on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Resource items */
/* Container for all resources */
/* Resource container */
.resource-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(100px, 1fr)
  ); /* Responsive grid */
  gap: 15px;
  max-width: 500px;
  width: 90%; /* Adjust width for smaller screens */
  z-index: 3; /* Above overlay */
  animation: fadeIn 0.3s ease; /* Optional fade-in effect */
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f44336; /* Red close button */
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease;
  z-index: 4; /* Above other elements */
}

.close-button:hover {
  background: #d32f2f; /* Darker on hover */
}

/* Resource item */
.resource-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease;
}

.resource-item:hover {
  transform: scale(1.05);
}

/* Resource icon */
.resource-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

/* Resource amount */
.resource-item span {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* Fade-in animation for the resource container */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
