/* Ship Resources Design */
.resources-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.resources-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.ship-type {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #e6f7ff;
  transition: transform 0.3s ease-in-out;
}

.ship-type:hover {
  transform: scale(1.02);
}

.ship-type-title {
  font-size: 1.5rem;
  color: #007bff;
}

.resources-list {
  list-style-type: none;
  padding: 0;
}

.resources-label {
  font-weight: bold;
}

.resource-value {
  color: #333;
  font-size: 1.1 rem;
}

.no-resource-warning {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  background-color: whitesmoke;
  color: orangered;
  border: 2px solid wheat;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
}

/* Load ship Design */
.crew-background {
  position: relative;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.crew-background ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../images/crew\ hire.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(4px);
  opacity: 0.7;
  z-index: -1;
}

.crew-background ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.shipDataDetails {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}

.shipInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.crewContainer {
  width: 45vw;
}

.shipInfoName {
  text-transform: capitalize;
}

.select-ship-role {
  border: 3px solid;
  z-index: 20;
}

.pirate-font {
  width: 40vw;
  font-family: "Pirate One", cursive;
  padding: 2%;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: #b8860b;
  border-radius: 80px;
}

.pirate-font h3 {
  font-size: 28px;
}

.addCrew {
  background-color: rgb(166, 80, 80);
}

/* Add Crew component */

.add-crew {
  z-index: 5;
}

.addcrew-h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: ghostwhite;
  font-weight: bold;
}

.close-button-crew {
  position: absolute;
  background-color: orangered;
  margin-left: 80%;
}

.close-button-crew:hover {
  background-color: whitesmoke;
}

.rarity {
  font-size: 20px;
  margin: 10px 0;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rarity:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Styling of different rarity level */

.legendary {
  background-color: gold;
  color: black;
}

.epic {
  background-color: #7d3cff;
}

.rare {
  background-color: #1e90ff;
}

.common {
  background-color: #a9a9a9;
}

.pirate-list-container {
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid goldenrod;
  padding: 10px;
  border-radius: 5px;
}

.pirate-show-container {
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid goldenrod;
  padding: 10px;
  border-radius: 5px;
}

/* Show Crew Members */
.show-crew-members {
  max-height: 450px;
  overflow-y: auto;
  position: absolute; /* Position the container */
  top: 20%; /* Adjust top position as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  z-index: 5; /* Ensure it appears above other elements */
  width: 70%;
  max-width: 500px; /* Limit width for better readability */
  margin: 0 auto; /* Center horizontally */
  padding: 20px;
  background-color: black; /* Set background color */
  border: 1px solid #000; /* Add border */
  border-radius: 5px; /* Add border radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
  cursor: default;
}

.show-crew-members ::before {
  content: none;
}
