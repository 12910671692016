.stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-heading {
  font-family: "Arial", sans-serif; /* Choose an appropriate font */
  font-size: 2.5rem; /* Adjust the font size */
  color: #4caf50; /* Set the text color */
  text-align: center; /* Center-align the text */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 2px; /* Add some letter spacing for emphasis */
  margin: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle text shadow */
  background-color: #f8f8f8; /* Add a light background color */
  padding: 20px; /* Add padding to create some space around the text */
  border-radius: 10px; /* Add border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  cursor: pointer;
}

.stats-heading:hover {
  color: #2ecc71; /* Change text color on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the box shadow on hover */
}

.stats-heading.active {
  background-color: #2ecc71; /* Distinctive background color for the active heading */
  color: #fff; /* White text color for the active heading */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase the box shadow for the active heading */
}

/* User Data  */

.user-stats-container {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.welcome-message {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.user-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.user-data-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-data-item p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.user-data-item strong {
  color: #333;
}

/* Global Stats */
.global-stats-container {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.global-stats-heading {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.global-stats-list {
  list-style-type: none;
  padding: 0;
}

.global-stats-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.global-stats-item:nth-child(even) {
  background-color: #f0f0f0;
}

.global-stats-item strong {
  margin-right: 10px;
  font-weight: bold;
}

.global-stats-item span {
  color: #777;
}

span {
  margin: 0 3px;
}

.withdraw-button {
  margin: 1%;
}

.claim-packs {
  margin-left: 2%;
}

.user-stats-container {
  position: relative;
}

.show-withdraw {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1001;
  width: 300px;
}

.show-withdraw button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.show-withdraw button:hover {
  background-color: #e55b4f;
}

.show-withdraw input {
  width: 50%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.withdraw-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.withdraw-button:hover {
  background-color: #0056b3;
}

.close-button {
  width: 5;
}

/* Nft Leaderboad Css */

/* Container for the leaderboard */
.leaderboard-div {
  max-width: 750px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Paragraph containing the leaderboard calculation formula */
.leaderboard-div.p {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Each leaderboard row */
.leaderboard-row {
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Alternating background color for rows */
.leaderboard-row:nth-child(odd) {
  background-color: #f9f9f9;
}

/* Hover effect for rows */
.leaderboard-row:hover {
  background-color: #f1f1f1;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Total text in each row */
.total {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

/* Details section initially hidden */
.details {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #eee;
  font-size: 14px;
  color: #666;
}

/* Loading text */
.leaderboard-div.p:last-child {
  text-align: center;
  font-size: 18px;
  color: darkslategray;
}

.leaderboard-payout-loot {
  color: #2ecc71;
}

.last-50 {
  color: lightslategray;
}
