.image-set {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.images-container {
  margin: 2%;
}
.image-item {
  width: calc(60%);
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.5s ease;
}

.image-item :hover {
  transform: scale(1.1);
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: auto;
}

.rarity-heading {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffd700;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  background: linear-gradient(45deg, #0ffc00, #ff9900);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  border-bottom: 2px solid #ffcc00;
  padding-bottom: 10px;
  text-align: center;
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #ffcc00, 0 0 20px #ff9900, 0 0 30px #ff6600;
  }
  to {
    text-shadow: 0 0 20px #ffcc00, 0 0 30px #ff9900, 0 0 40px #ff6600;
  }
}

button {
  padding: 10px 20px; /* Add padding to the button */
  margin: 5px;
  border-radius: 5px; /* Set border radius to create rounded corners */
  background-color: #4caf50; /* Add background color */
  color: white; /* Set text color */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 16px; /* Set font size */
  transition: background-color 0.3s ease, transform 0.5s ease;
}

button:hover {
  background-color: #45a049; /* Darken background color on hover */
  transform: scale(1.1);
}

button:focus {
  outline: none; /* Remove outline on focus */
}

.sellButton {
  background-color: red;
}

.sellButton:hover {
  background-color: darkred;
}

.transferButton {
  background-color: lightskyblue;
}

.transferButton:hover {
  background-color: darkblue;
}

.currentCardContainer {
  display: flex;
  align-items: center;
  width: 50%;
}

.currentCardIds {
  display: block;
  margin: 5px;
}

select {
  padding: 8px 12px; /* Add padding to the select element */
  font-size: 16px; /* Set font size */
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Add border radius */
  appearance: none; /* Remove default appearance */
  background-color: #fff; /* Set background color */
  cursor: pointer; /* Change cursor to pointer */
}

/* Style the selected option */
select option:checked {
  background-color: #4caf50; /* Set background color */
  color: #fff; /* Set text color */
}

button:disabled {
  background-color: #ccc; /* Set background color to grey */
  color: #666; /* Set text color to a darker shade */
  cursor: not-allowed; /* Change cursor to indicate button is disabled */
}

.sell-container {
  position: absolute; /* Position the container */
  top: 20%; /* Adjust top position as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  z-index: 3; /* Ensure it appears above other elements */
  width: 70%;
  max-width: 500px; /* Limit width for better readability */
  margin: 0 auto; /* Center horizontally */
  padding: 20px;
  background-color: #fff; /* Set background color */
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Add border radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
  cursor: default;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 2; /* Ensure it appears above other content */
  pointer-events: auto; /* Enable pointer events to block interactions */
  cursor: not-allowed;
}

.close-button {
  background-color: white;
  color: grey;
  position: fixed;
  top: 0;
  right: 0;
}

.close-button:hover {
  background-color: black;
}
