.navbar {
  background-color: #212121; /* Dark background color */
  padding: 2%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.nav-item {
  font-size: 1.3rem;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.nav-item:hover {
  background-color: #4caf50; /* Green background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow effect */
  border-radius: 5px; /* Border radius */
  transform: translateY(-2px); /* Slight upward lift */
}

.nav-item:hover .nav-link {
  color: white; /* Change text color to white on hover */
}

.nav-link {
  color: #ccc; /* Light gray text color */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
}

.nav-link :hover {
  color: #fff; /* White text color on hover */
  transform: scale(1.1); /* Slightly larger size on hover */
}
